var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.pagingInfo.total > 1 &&
    _vm.pagingInfo.per_page / _vm.pagingInfo.total < 1
    ? _c("div", [
        _c("nav", [
          _c(
            "ul",
            { staticClass: "pagination", class: _vm.justifyContentAlignment },
            _vm._l(_vm.pageLinks, function (link) {
              return _c("div", { key: link.label }, [
                _c(
                  "li",
                  {
                    staticClass: "pr-1",
                    class:
                      "page-item " +
                      (link.url == null ? "disabled" : "") +
                      " " +
                      (link.active == true ? "active" : ""),
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "page-link",
                        attrs: { "aria-label": link.label },
                        on: {
                          click: function ($event) {
                            return _vm.goToPage(link.url)
                          },
                        },
                      },
                      [
                        _c("button", {
                          domProps: {
                            innerHTML: _vm._s(_vm.decodeHtml(link.label)),
                          },
                        }),
                      ]
                    ),
                  ]
                ),
              ])
            }),
            0
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }