var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card card-custom gutter-b" },
    [
      _c("div", { staticClass: "card-header border-0 py-5" }, [
        _c("h3", { staticClass: "card-title align-items-start flex-column" }, [
          _c(
            "span",
            { staticClass: "card-label font-weight-bolder text-dark" },
            [_vm._v(" " + _vm._s(_vm.title) + " ")]
          ),
        ]),
      ]),
      _c("div", { staticClass: "card-body py-0" }, [
        _c(
          "div",
          { staticClass: "table-responsive table-hover" },
          [
            _c(
              "table",
              { staticClass: "table table-head-custom table-vertical-center" },
              [
                _vm.headers.length < 1
                  ? _c("thead")
                  : _c("thead", [
                      _c(
                        "tr",
                        { staticClass: "text-center" },
                        [
                          _c("th", [_vm._v("No.")]),
                          _vm._l(_vm.headers, function (header) {
                            return _c("th", { key: header.value }, [
                              _vm._v(" " + _vm._s(header.text) + " "),
                            ])
                          }),
                          _c("th", [_vm._v("Tindakan")]),
                        ],
                        2
                      ),
                    ]),
                !_vm.pagedData.data || _vm.headers.length < 1
                  ? _c("tbody", { attrs: { id: "loader" } })
                  : _c(
                      "tbody",
                      [
                        _vm._l(
                          this.pagedData.data,
                          function (application, applicationIdx) {
                            return _c(
                              "tr",
                              {
                                key: application.id,
                                staticClass: "text-center",
                              },
                              [
                                _c("td", { staticClass: "pl-0" }, [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "text-dark-75 font-weight-bolder d-block font-size-md",
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(+applicationIdx + 1) + " "
                                      ),
                                    ]
                                  ),
                                ]),
                                _vm._l(
                                  _vm.headers,
                                  function (header, headerIdx) {
                                    return _c(
                                      "td",
                                      {
                                        key: header.value,
                                        staticClass: "pl-0",
                                      },
                                      [
                                        header.tooltip
                                          ? _c(
                                              "v-tooltip",
                                              {
                                                attrs: { bottom: "" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function (ref) {
                                                        var on = ref.on
                                                        var attrs = ref.attrs
                                                        return [
                                                          _c(
                                                            "div",
                                                            _vm._g(
                                                              _vm._b(
                                                                {
                                                                  staticClass:
                                                                    "text-dark-75 font-weight-bolder d-block font-size-md",
                                                                  staticStyle: {
                                                                    "white-space":
                                                                      "nowrap",
                                                                    "text-overflow":
                                                                      "ellipsis",
                                                                    overflow:
                                                                      "hidden",
                                                                    "max-width":
                                                                      "270px",
                                                                  },
                                                                },
                                                                "div",
                                                                attrs,
                                                                false
                                                              ),
                                                              on
                                                            ),
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.showValue(
                                                                      application,
                                                                      header,
                                                                      headerIdx
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              },
                                              [
                                                _c("div", {
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      _vm.genHTML(
                                                        application,
                                                        header,
                                                        headerIdx
                                                      )
                                                    ),
                                                  },
                                                }),
                                              ]
                                            )
                                          : _c("div", {
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  _vm.genHTML(
                                                    application,
                                                    header,
                                                    headerIdx
                                                  )
                                                ),
                                              },
                                            }),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                _c(
                                  "td",
                                  { staticClass: "pr-0 text-center" },
                                  [
                                    _vm.detailButton
                                      ? _c(
                                          "v-btn",
                                          {
                                            staticClass:
                                              "btn btn-icon btn-light btn-hover-primary btn-sm",
                                            attrs: { icon: "" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.doDetails(
                                                  application
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "svg-icon svg-icon-md svg-icon-primary",
                                              },
                                              [
                                                _c("inline-svg", {
                                                  attrs: {
                                                    src: "media/svg/icons/Communication/Write.svg",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.deleteButton
                                      ? _c(
                                          "v-btn",
                                          {
                                            staticClass:
                                              "btn btn-icon btn-hover-danger btn-sm",
                                            attrs: { icon: "" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.doDelete(application)
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "svg-icon svg-icon-md svg-icon-danger",
                                              },
                                              [
                                                _c("inline-svg", {
                                                  attrs: {
                                                    src: "media/svg/icons/Files/Rejected-file.svg",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              2
                            )
                          }
                        ),
                      ],
                      2
                    ),
              ]
            ),
            _vm.pagedData.data && _vm.headers.length > 0
              ? _c("Pagination", {
                  attrs: { pagingInfo: _vm.pageLinks, align: "center" },
                  on: { onClickPage: _vm.loadPageData },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c(
        "v-overlay",
        { attrs: { value: _vm.overlay } },
        [
          _c("v-progress-circular", {
            attrs: { indeterminate: "", size: "64" },
          }),
        ],
        1
      ),
      _c("ErrorMsgDialog", {
        attrs: {
          modal: _vm.msgERR.modal,
          title: _vm.msgERR.title,
          content: _vm.msgERR.message,
          showProceedButton: true,
          showBackButton: false,
          delayOnProceed: false,
          isTypeError: true,
          proceedButton: "OK",
        },
        model: {
          value: _vm.msgERR.show,
          callback: function ($$v) {
            _vm.$set(_vm.msgERR, "show", $$v)
          },
          expression: "msgERR.show",
        },
      }),
      _c("InfoMsgDialog", {
        attrs: {
          modal: _vm.msgINFO.modal,
          title: _vm.msgINFO.title,
          content: _vm.msgINFO.message,
          showProceedButton: true,
          showBackButton: false,
          delayOnProceed: false,
          proceedButton: "OK",
        },
        on: {
          onProceed: function ($event) {
            return _vm.infoProceedOK()
          },
        },
        model: {
          value: _vm.msgINFO.show,
          callback: function ($$v) {
            _vm.$set(_vm.msgINFO, "show", $$v)
          },
          expression: "msgINFO.show",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }