<template>
  <v-dialog v-model="displayAppLogsDialog" max-width="600">
    <v-card class="pa-6">
      <v-card-text>
        <v-timeline dense clipped>
          <v-slide-x-transition group>
            <v-timeline-item
              v-for="log in logs"
              :key="log.id"
              class="mb-4"
              small
            >
              <v-row justify="space-between">
                <v-col cols="7">
                  <span>{{ log.description }}</span>
                </v-col>
                <v-col class="text-right" cols="5">
                  <span>{{ log.date | formatDateTime }}</span>
                </v-col>
              </v-row>
            </v-timeline-item>
          </v-slide-x-transition>
        </v-timeline>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn  @click="closeDialog()">Tutup</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "AppLogsDialog",
  props: {
    value: {
      type: Boolean,
      default: true,
    },
    logs: Array
  },
  data() {
    return {};
  },
  computed: {
    displayAppLogsDialog: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
  },
  mounted() {},
  methods: {
    closeDialog() {
        this.displayAppLogsDialog = false;
    }
  },
};
</script>