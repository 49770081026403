<template>
  <div
    class="card card-custom gutter-b"
  >
    <div
      class="card-header border-0 py-5"
    >
      <h3
        class="card-title align-items-start flex-column"
      >
        <span
          class="card-label font-weight-bolder text-dark"
        > {{ title }} </span>
      </h3>
    </div>

    <div
      class="card-body py-0"
    >
      <!-- v-if="pagedDataExists"  -->
      <div
        class="table-responsive table-hover"
      >
        <table
          class="table table-head-custom table-vertical-center"
        >
          <thead
            v-if="headers.length < 1"
          >
          </thead>

          <thead
            v-else
          >
            <tr
              class="text-center"
            >
              <th>No.</th>
              <th
                v-for="(header) in headers"
                :key="header.value"
              >
                {{ header.text }}
              </th>
              <th>Tindakan</th>
            </tr>
          </thead>

          <tbody
            v-if="!pagedData.data || headers.length < 1"
            id="loader"
          >
            <!-- <tr
              class="text-center"
            >
              <td
                colspan="9"
              >
                <v-progress-circular
                  indeterminate
                  color="primary"
                >
                  Menunggu Data...
                </v-progress-circular>
              </td>
            </tr> -->
          </tbody>

          <tbody
            v-else
          >
            <template>
              <tr
                v-for="(application, applicationIdx) in this.pagedData.data"
                class="text-center"
                :key="application.id"
              >
                <td
                  class="pl-0"
                >
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-md"
                  >
                    {{ +applicationIdx + 1 }}
                  </span>
                </td>
                <td
                  v-for="(header, headerIdx) in headers" :key="header.value"
                  class="pl-0"
                >
                  <v-tooltip
                    v-if="header.tooltip"
                    bottom
                  >
                    <template
                      v-slot:activator="{ on, attrs }"
                    >
                      <div
                        class="text-dark-75 font-weight-bolder d-block font-size-md"
                        style="white-space: nowrap; text-overflow: ellipsis; overflow: hidden; max-width: 270px;"
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ showValue(application, header, headerIdx) }}
                      </div>
                    </template>
                    <div
                      v-html="genHTML(application, header, headerIdx)"
                    ></div>
                  </v-tooltip>
                  <div
                    v-else
                    v-html="genHTML(application, header, headerIdx)"
                  ></div>
                </td>
                <td
                  class="pr-0 text-center"
                >
                  <v-btn
                    v-if="detailButton"
                    icon
                    class="btn btn-icon btn-light btn-hover-primary btn-sm"
                    @click="doDetails(application)"
                  >
                    <span
                      class="svg-icon svg-icon-md svg-icon-primary"
                    >
                      <inline-svg
                        src="media/svg/icons/Communication/Write.svg"
                      ></inline-svg>
                    </span>
                  </v-btn>
                  <v-btn
                    v-if="deleteButton" icon class="btn btn-icon btn-hover-danger btn-sm"
                    @click="doDelete(application)"
                  >
                    <span
                      class="svg-icon svg-icon-md svg-icon-danger"
                    >
                      <inline-svg
                        src="media/svg/icons/Files/Rejected-file.svg"
                      ></inline-svg>
                    </span>
                  </v-btn>
                </td>
              </tr>
            </template>
          </tbody>
        </table>

        <Pagination
          v-if="pagedData.data && headers.length > 0"
          :pagingInfo="pageLinks"
          :align="'center'"
          @onClickPage="loadPageData"
        ></Pagination>

      </div>
      <!-- <div v-else class="card-body border-0 py-5">
        <p class="card-title align-items-start flex-column">
          <span class="card-label text-dark"> Tiada data "{{ title }}" dijumpai. </span>
        </p>
      </div> -->

    </div>

    <v-overlay
      :value="overlay"
    >
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>


    <ErrorMsgDialog
      v-model="msgERR.show"
      :modal="msgERR.modal"
      :title="msgERR.title"
      :content="msgERR.message"
      :showProceedButton="true"
      :showBackButton="false"
      :delayOnProceed="false"
      :isTypeError="true"
      proceedButton="OK"
    ></ErrorMsgDialog>

    <InfoMsgDialog
      v-model="msgINFO.show"
      :modal="msgINFO.modal"
      :title="msgINFO.title"
      :content="msgINFO.message"
      :showProceedButton="true"
      :showBackButton="false"
      :delayOnProceed="false"
      proceedButton="OK"
      @onProceed="infoProceedOK()"
    ></InfoMsgDialog>

  </div>
</template>

<script>
import {
  mapGetters,
} from "vuex";

import {
  isObject,
  errorMessages,
  verifyPagedData,
  extractPagingInfoOnly,
} from "@/core/services/helper.service";

import {
  GET_APPS_BY_URL,
  GET_APPS_ROLE_MEMBER,
  SET_PAGED_APPS_ROLE_MEMBER,
} from "@/core/services/store/application.module";

import Pagination from "@/view/pages/widgets/ui/Pagination.vue";

export default {
  name: "ApplicationList",

  components: {
    Pagination,
  },

  props: {
    title: {
      type: String,
      default: "Senarai Permohonan",
    },

    detailButton: {
      type: Boolean,
      default: false,
    },

    deleteButton: {
      type: Boolean,
      default: false,
    },

    appsConfig: {
      type: Array,
      default: () => { return []; },
    },

    appsData: {
      type: Object,
      default: () => {
        return {
          data: [],
          current_page: 0,
          per_page: 0,
          from: 0,
          to: 0,
          last_page: 0,
          total: 1,
          first_page_url: null,
          last_page_url: null,
          prev_page_url: null,
          next_page_url: null,
          links: [],
          path: null,
        };
      },
    },
  },

  watch: {
    appsData(newValue, oldValue) {
      //nfh-console.log('WATCH appsData:', newValue, oldValue);
      this.localAppsData = this.appsData;
    },

    appsConfig(newValue, oldValue) {
      //nfh-console.log('WATCH appsConfig:', newValue, oldValue);
      this.localHeaders = this.appsConfig;
    },

  },

  data() {
    return {
      localAppsData: {
        data: [],
        current_page: 0,
        per_page: 0,
        from: 0,
        to: 0,
        last_page: 0,
        total: 1,
        first_page_url: null,
        last_page_url: null,
        prev_page_url: null,
        next_page_url: null,
        links: [],
        path: null,
      },
      overlay: true,

      msgERR: {
        show: false,
        modal: false,
        title: "Ralat",
        message: "",
        nric: "",
        run: 0,
      },

      msgINFO: {
        show: false,
        modal: false,
        title: "Info",
        message: "",
        nric: "",
        run: 0,
      },

      localHeaders: [
      ],

      options: {
        itemsPerPage: 0
      },

    };
  },

  computed: {
    ...mapGetters([
      // "pagedAppsRoleMember",
    ]),

    pagedData() {
      return this.localAppsData;
    },

    pagedDataExists() {
      return verifyPagedData(this.pagedData);
    },

    pageLinks() {
      return extractPagingInfoOnly(this.pagedData);
    },

    getDataTableOption: {
      get() {
        return { itemsPerPage: this.pagedData?.per_page };
      },

      set(value) {
        //nfh-console.log("getDataTableOption:set - ", value)
        this.options = value;
      }
    },

    headers: {
      get() {
        return this.localHeaders;
      },
      set(value) {
        this.localHeaders = value;
      }
    },

    appsDataPagination() {
      return {
        page: this.pagedData?.current_page ?? 0,
        itemsPerPage: this.pagedData?.per_page ?? 0,
        pageStart: this.pagedData?.from ?? 0,
        pageStop: this.pagedData?.to ?? 0,
        pageCount: this.pagedData?.last_page ?? 0,
        itemsLength: this.pagedData?.total ?? 0,
      };
    },
  },

  created() {
    //nfh-console.log('CREATED this.appsData: ', this.appsData);
    this.localAppsData = this.appsData;
    this.localHeaders = this.appsConfig;
    //nfh-console.log('CREATED this.localAppsData: ', this.localAppsData);
  },

  async mounted() {
    //nfh-console.log(" IN: MOUNTED");
    this.closeOverlay();
    // await this.$store.dispatch(GET_APPS_ROLE_MEMBER);

    //nfh-console.log('MOUNTED this.appsData: ', this.appsData);
    //nfh-console.log('MOUNTED this.localAppsData: ', this.localAppsData);
    //nfh-console.log("OUT: MOUNTED");
  },

  methods: {
    genHTML(sourceObj, attr, i) {
      let _colorClass = [];
      let _templateClass = [];
      let _staticClass = [
        "d-block",
        "font-size-md",
        "font-weight-bolder",
      ];
      let _style = 'style="' + attr.style + '"';
      let _value = this.showValue(sourceObj, attr, i);

      if (attr?.color) {
        _colorClass.push('label-' + this.objectDepthValue(sourceObj, attr.color));
      }

      for (const key in attr.class_template) {
        if (attr.class_template[key]) {
          _templateClass.push(key);
        }
      }

      let _class = 'class="' + [..._colorClass, ..._templateClass, ..._staticClass].join(" ") + '"';
      // console.log('_class: ', _class);

      return `<span ${_class} ${_style}>${_value}</span>`;
    },

    objectDepthValue(sourceObj, attrName) {
      if (isObject(sourceObj)) {
        const _levels = attrName?.split(".");
        let _attr = sourceObj[_levels[0]];

        for (let j = 1; j < _levels.length; j++) {
          _attr = (_attr) ? _attr[_levels[j]] : _attr;
        }
        return _attr;
      }
      return null;
    },

    showValue(sourceObj, attr, i) {
      if (isObject(sourceObj)) {
        const _levels = attr?.value?.split(".");
        let _attr = sourceObj[_levels[0]];

        for (let j = 1; j < _levels.length; j++) {
          _attr = (_attr) ? _attr[_levels[j]] : _attr;
        }

        if (!(typeof _attr == 'undefined' || _attr == null)) {
          return (attr.filter)
            ? this.$options.filters[attr.filter](_attr)
            : _attr;
        }
      }
      return "";
    },

    updatePage(value) {
      //nfh-console.log("updatePage: ", value);
    },

    updateOption(value) {
      //nfh-console.log("updateOption:", value);
    },

    async loadPageData(page) {
      //nfh-console.log('loadPageData - page: ', page);
      this.openOverlay();

      if (page?.success) {
        // await this.$store.commit(SET_PAGED_APPS_ROLE_MEMBER, page?.data);
        this.localAppsData = page?.data ?? {};
        // this.localAppsData = this.pagedAppsRoleMember ?? {};
        //nfh-console.log('MOUNTED this.localAppsData: ', this.localAppsData);
      }
      else {
        this.promptError(page?.message, page?.error);
      }

      this.closeOverlay();
    },

    async paginate(url) {
      this.openOverlay();
      // await this.$store
      //   .dispatch(GET_APPS_BY_URL, url)
      //   .then(async (resp) => {
      //     if (resp.data.success) {
      //       this.$store.commit(SET_PAGED_APPS_ROLE_MEMBER, resp.data.data);
      //     }
      //     else {
      //       promptError(resp?.data?.message, resp?.data?.error);
      //     }
      //   });
      await this.$store.dispatch(GET_APPS_BY_URL, url);
      this.closeOverlay();
    },

    doDetails(data) {
      this.$emit("onDetail", data);
    },

    doDelete(data) {
      this.$emit("onDelete", data);
    },

    promptError(message, title = null) {
      this.msgERR.message = errorMessages(message);
      this.msgERR.title   = (title) ? title : "";
      this.msgERR.show    = true;
    },

    promptInfo(message, title = null, modal = false) {
      this.msgINFO.message = message;
      this.msgINFO.title   = (title) ? title : "";
      this.msgINFO.modal   = modal;
      this.msgINFO.show    = true;
    },

    openOverlay() {
      this.overlay = true;
    },

    closeOverlay() {
      setTimeout(() => {
        this.overlay = false;
      }, 200);
    },

  },
};
</script>

<style scoped>
#loader {
  width: 100%;
  height: 100px;
}
</style>
