var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "600" },
      model: {
        value: _vm.displayAppLogsDialog,
        callback: function ($$v) {
          _vm.displayAppLogsDialog = $$v
        },
        expression: "displayAppLogsDialog",
      },
    },
    [
      _c(
        "v-card",
        { staticClass: "pa-6" },
        [
          _c(
            "v-card-text",
            [
              _c(
                "v-timeline",
                { attrs: { dense: "", clipped: "" } },
                [
                  _c(
                    "v-slide-x-transition",
                    { attrs: { group: "" } },
                    _vm._l(_vm.logs, function (log) {
                      return _c(
                        "v-timeline-item",
                        {
                          key: log.id,
                          staticClass: "mb-4",
                          attrs: { small: "" },
                        },
                        [
                          _c(
                            "v-row",
                            { attrs: { justify: "space-between" } },
                            [
                              _c("v-col", { attrs: { cols: "7" } }, [
                                _c("span", [_vm._v(_vm._s(log.description))]),
                              ]),
                              _c(
                                "v-col",
                                {
                                  staticClass: "text-right",
                                  attrs: { cols: "5" },
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(_vm._f("formatDateTime")(log.date))
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  on: {
                    click: function ($event) {
                      return _vm.closeDialog()
                    },
                  },
                },
                [_vm._v("Tutup")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }