<template>
  <div
    v-if="pagingInfo.total > 1 && pagingInfo.per_page / pagingInfo.total < 1"
  >
    <nav>
      <ul
        class="pagination"
        :class="justifyContentAlignment"
      >
        <div
          v-for="link in pageLinks"
          :key="link.label"
        >
          <li
            class="pr-1"
            v-bind:class="`page-item ${link.url == null ? 'disabled' : ''} ${link.active == true ? 'active' : ''}`"
          >
            <a
              class="page-link"
              @click="goToPage(link.url)"
              :aria-label="link.label"
            >
              <button
                v-html="decodeHtml(link.label)"
              ></button>
              <!-- <b-icon icon="chevron-bar-left"></b-icon> -->
            </a>
          </li>
        </div>
      </ul>
    </nav>
  </div>
</template>

<script>
import {
  FETCH_RAW_URL,
} from "@/core/services/store/helper.module";

export default {
  name: "Pagination",

  components: {
  },

  props: {
    pagingInfo: {
      type: Object,
      default: () => {
        return {
          data: [],
          current_page: 0,
          per_page: 0,
          from: 0,
          to: 0,
          last_page: 0,
          total: 1,
          first_page_url: null,
          last_page_url: null,
          prev_page_url: null,
          next_page_url: null,
          links: [],
          path: null,
        };
      },
    },

    align: {
      type: String,
      default: "left",
    },
  },

  watch: {
    pagingInfo(newValue, oldValue) {
      //nfh-console.log("WATCH - pagingInfo: ", newValue, oldValue)
    }
  },

  data() {
    return {
    };
  },

  computed: {
    justifyContentAlignment() {
      switch (this?.align?.trim()?.toLowerCase()) {
        case "center":
          return {
            "justify-content-center": true,
          };
          break;

        case "right":
          return {
            "justify-content-end": true,
          };
          break;

        default:
          return {
            "justify-content-start": true,
          }
      }
    },

    isAtLastPage() {
      return this.pagingInfo.current_page === this.pagingInfo.last_page;
    },

    isAtFirstPage() {
      return this.pagingInfo.current_page === 1;
    },

    pageLinks() {
      var _first = [{
          active: false,
          label: "&#10073;&lt;",
          // label: 'chevron-bar-left',
          url: (this.isAtFirstPage) ? null : this.pagingInfo.first_page_url,
      }];
      var _last = [{
          active: false,
          label: "&gt;&#10073;",
          // label: 'chevron-bar-right',
          url: (this.isAtLastPage) ? null : this.pagingInfo.last_page_url,
      }];
      return [..._first, ...this.pagingInfo.links, ..._last];
    }
  },

  methods: {
    async goToPage(url) {
      await this.$store.dispatch(FETCH_RAW_URL, url)
        .then((resp) => {
          if (resp?.status == 401) {
            //nfh-console.log('401:Unauthenticated');
            this.$router.push("/login");
          }
          else if (String(resp?.message).toLowerCase().trim() == "unauthenticated") {
            //nfh-console.log('message:Unauthenticated');
            this.$router.push("/login");
          }
          else if (resp?.success) {
            //nfh-console.log("FETCH_RAW_URL - resp", resp);
            this.$emit("onClickPage", resp);
          }
          else {
            this.$emit("onClickPageError", resp);
          }
        })
        .catch(err => {
          //nfh-console.log('Pagination Catch Error: ', err);
          this.$emit("onClickPageError", {
            // ...resp?.data,
            ...err,
          });
        });
    },

    decodeHtml: function (html) {
      if (html == "chevron-bar-left" || html == "chevron-bar-right") {
        var icon = document.createElement("b-icon");
        //nfh-console.log('icon: ', icon)
        icon.setAttribute('icon', html);
        return icon.outerHTML;
        // <b-icon icon="x" scale="2" variant="dark"></b-icon>
      }
      else {
        var txt = document.createElement("textarea");
        txt.innerHTML = html;
        return txt.value;
      }
    },

  },
};
</script>